.mat-form-field {
  width: 100%;

  .mat-form-field-wrapper {
    padding-bottom: 0;

    .mat-form-field-flex {
      background: $white;
      border-radius: $border-radius;
      padding: 0.45rem 0.75rem 0 1.25rem;
      transition: background-color 0.25s;

      @include bp(desk) {
        padding: 0.5rem 0.75rem 0 1.25rem;
      }

      .mat-form-field-infix {
        padding: 0 0 0.45rem;
        border-top: 0.75rem solid rgba(0, 0, 0, 0);

        @include bp(desk) {
          padding: 0.25rem 0 0.5rem;
        }

        .mat-form-field-label-wrapper {
          top: -0.85rem;
          padding-top: 0.85rem;

          @include bp(desk) {
            top: -0.75rem;
            padding-top: 0.75rem;
          }

          .mat-form-field-label {
            color: $colTertiaryMediumgrey1;
            font-size: rem(14px);
            line-height: 1.2;

            @include bp(desk) {
              font-size: 1rem;
            }
          }
        }

        .mat-input-element {
          color: $colBody;
          font-size: rem(14px);
          line-height: 1.2;

          @include bp(desk) {
            font-size: 1rem;
          }
        }
      }

      .mat-form-field-prefix,
      .mat-form-field-suffix {
        .mat-icon-button {
          color: $colBody;
          transform: translateY(-0.2rem);
          -moz-transform: none;
          height: 1.5rem !important;
          width: 1.5rem !important;

          @include bp(desk) {
            transform: translateY(-0.125rem);
            -moz-transform: none;
            width: 2rem !important;
            height: 2rem !important;
          }

          .mat-icon {
            display: flex;
            font-size: 120%;

            @include bp(desk) {
              font-size: 150%;
              width: 2rem !important;
              height: 2rem !important;
            }
          }
        }
      }

      .mat-form-field-prefix {
        padding-right: 0.5rem;
      }
    }

    .mat-form-field-underline {
      display: none;
    }

    .mat-form-field-subscript-wrapper {
      position: static;
      margin-top: 0;
      padding: 0 1.5rem;
      mat-error,
      mat-hint {
        padding-top: 0.5rem;
        &.is-white {
          color: $white;
        }
      }
    }

    &:not(.mat-form-field-appearance-legacy)
      .mat-form-field-prefix
      .mat-icon-button,
    &:not(.mat-form-field-appearance-legacy)
      .mat-form-field-suffix
      .mat-icon-button {
      width: 2rem;
      height: 2rem;

      @include bp(desk) {
        width: 2.5rem;
        height: 2.5rem;
      }
    }
  }

  &.mat-focused {
    .mat-form-field-flex {
      background: $white !important;
    }
    .mat-form-field-label {
      // color: $colPrimaryPink !important;
      color: var(--primary-color) !important;
    }
  }

  &.mat-form-field-appearance-fill {
    .mat-form-field-flex {
      background-color: $white;
    }

    .mat-form-field-label {
      top: 1rem;
      margin-top: -0.5rem;
    }
  }

  &.mat-form-field-disabled {
    .mat-input-element {
      color: $colBody !important;
    }
  }
}

// Form field w/ dark grey background
.mat-form-field.has-bgDark {
  .mat-form-field-flex {
    background: $colTertiaryMediumgrey2;
    transition: background-color 0.25s;

    .mat-form-field-infix .mat-form-field-label {
      color: $colBody;
    }
  }

  &.mat-form-field-appearance-fill .mat-form-field-flex {
    background-color: $white;
  }
}

// Form field w/ border
.mat-form-field.has-border {
  .mat-form-field-wrapper .mat-form-field-flex {
    border: 1px solid $colTertiaryLightgrey3;
  }
  .mat-form-field-prefix .mat-icon,
  .mat-form-field-suffix .mat-icon {
    color: $colTertiaryMediumgrey1;
  }
}

// Form field w/ light grey background
.mat-form-field.has-bgLight {
  .mat-form-field-flex {
    background: $colTertiaryLightgrey2;
    border: 1px solid transparent;
    transition: border 0.25s;

    .mat-form-field-suffix button {
      background: $white;
      margin-left: 0.5rem;
    }

    .mat-form-field-prefix,
    .mat-form-field-suffix {
      .mat-icon-button {
        transform: translateY(-0.25rem);
        width: 2rem !important;
        height: 2rem !important;
        display: inline-flex !important;
        color: $colTertiaryMediumgrey1;
        transition: color 0.25s;

        @include bp(desk) {
          transform: translateY(-0.25rem);
          -moz-transform: translateY(-0.35rem);
          width: 2.5rem !important;
          height: 2.5rem !important;
        }

        &:not(.mat-button-disabled):hover,
        &:not(.mat-button-disabled):focus {
          color: var(--primary-color);
        }

        .mat-icon {
          height: 1.5rem !important;
          width: 1.5rem !important;
          display: flex;
        }
      }
    }
  }

  &.mat-focused .mat-form-field-flex {
    background: $colTertiaryLightgrey2;
    border: 1px solid $colTertiaryLightgrey3;
  }

  &.hasIconOnly {
    .mat-form-field-suffix {
      button {
        background: transparent;
        transform: translateY(-0.12rem) !important;
        -moz-transform: translateY(-0.125rem) !important;
      }
    }
  }
}

// Removes autofill default background
input:-internal-autofill-selected {
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0),
    inset 0 0 0 100px rgba(255, 255, 255, 1);
}

//Checkbox field
.mat-checkbox {
  width: 100%;
  padding: 0 1.5rem;

  label {
    font-size: 1rem;
  }

  &.mat-checkbox-checked .mat-checkbox-background {
    svg.mat-checkbox-checkmark {
      padding: rem(2px);
      .mat-checkbox-checkmark-path {
        stroke-width: 0.25rem;
      }
    }
  }

  a {
    text-decoration: underline;
    transition: color 0.25s;

    &:hover {
      color: $colTertiaryLightgrey3;
    }
  }

  &.is-light {
    label {
      color: $white;
    }

    .mat-checkbox-frame {
      border-color: $white;
    }

    &.mat-checkbox-checked .mat-checkbox-background {
      background: transparent;
    }
  }
}

// Custom checkbox styles for checkbox labels containing <a> + error and hint styles
.fieldCheckbox {
  .fieldCheckbox__withLink {
    display: inline-flex;
    mat-checkbox {
      width: auto;
      padding-right: 0.25rem;
    }

    .fieldCheckbox__link {
      margin-top: 0.125rem;
      padding-right: 1.5rem;

      a {
        text-decoration: underline;
        transition: color 0.25s;
        &:hover {
          color: $colTertiaryLightgrey3;
        }
      }
    }
  }

  mat-error,
  mat-hint {
    padding: 0.5rem 1.5rem;
    font-size: 75%;
    &.is-white {
      color: $white;
    }
  }
}

mat-error,
mat-hint {
  &.is-white {
    color: $white;
  }
}

// Datepicker calendar
mat-calendar {
  .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    border-color: var(--primary-color);
  }

  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
    > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: color-mix(in srgb, var(--primary-color) 70%, $white 30%);
  }

  .mat-calendar-body-selected {
    background-color: var(--primary-color);
  }

  .cdk-keyboard-focused
    .mat-calendar-body-active
    > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
  .cdk-program-focused
    .mat-calendar-body-active
    > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: color-mix(in srgb, var(--primary-color) 70%, $white 30%);
  }
}

.mat-datepicker-toggle .mat-icon-button {
  background: transparent !important;
  transform: translateY(0) !important;

  @include bp(desk) {
    transform: translateY(-0.15rem) !important;
    -moz-transform: none !important;
  }
}

.mat-form-field.has-bgLight {
  .mat-datepicker-toggle .mat-icon-button {
    transform: translateY(0) !important;

    @include bp(desk) {
      transform: translateY(-0.125rem) !important;
      -moz-transform: translateY(-0.25rem) !important;
    }
  }
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  width: 2.5rem !important;
  height: 2.5rem !important;
}

.mat-datepicker-toggle-active {
  color: var(--primary-color);
}

// Select field
.mat-select-panel .mat-optgroup-label,
.mat-select-panel .mat-option {
  line-height: 1.5 !important;
  height: auto !important;
}

.mat-option {
  padding: 0.75rem 1rem !important;
}

.mat-option-text {
  white-space: normal;
}

// Mobile number fields
.ngx-mat-tel-input-container {
  .country-selector {
    padding: 0 0.5rem 0 0 !important;
    width: auto !important;
    background-image: url("../../assets/images/icons/icon-chevron-down.svg") !important;
    background-size: 0.75rem auto !important;
    margin-bottom: 0.5rem;

    @include bp(desk) {
      margin-bottom: 0.25rem;
    }

    > .mat-button-wrapper {
      margin-right: 0.75rem;

      .country-selector-flag {
        vertical-align: middle;
        margin-bottom: 0.75rem;

        @include bp(desk) {
          margin-bottom: rem(2px);
        }
      }

      .country-selector-code {
        vertical-align: super;
        color: $colBody;
        font-size: rem(14px);
        line-height: 1.2;

        @include bp(desk) {
          font-size: 1rem;
          vertical-align: middle;
        }
      }
    }
  }
}
