@font-face {
  font-family: "Roboto";
  src: url("../../assets/fonts/roboto-light.woff2") format("woff2"),
    url("../../assets/fonts/roboto-light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("../../assets/fonts/roboto-regular.woff2") format("woff2"),
    url("../../assets/fonts/roboto-regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("../../assets/fonts/roboto-medium.woff2") format("woff2"),
    url("../../assets/fonts/roboto-medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("../../assets/fonts/roboto-bold.woff2") format("woff2"),
    url("../../assets/fonts/roboto-bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

html {
  font-size: 100%;
}

// $headline = h1, $title = h2, $subheading-2 = h3, $subheading-1 = h4
$gatho-typography: mat-typography-config(
  $font-family: $roboto,
  $headline: mat-typography-level(2.5rem, 1.2, $fontHeadingWeight),
  $title: mat-typography-level(2rem, 1.2, $fontHeadingWeight),
  $subheading-2: mat-typography-level(1.75rem, 1.2, $fontHeadingWeight),
  $subheading-1: mat-typography-level(1.5rem, 1.2, $fontHeadingWeight),
  $body-1: mat-typography-level(1rem, 1.5, $fontBodyWeight),
  $caption: mat-typography-level(0.75rem, 1.5, $fontBodyWeight),
);

@include mat-base-typography($gatho-typography);

h5,
.mat-typography h5 {
  font-size: 1.25rem;
  line-height: 1.2;
  font-weight: $fontHeadingWeight;
}

h6,
.mat-typography h6 {
  font-size: 1rem;
  line-height: 1.5;
  font-weight: $fontHeadingWeight;
}

h1,
h2,
h3,
h4,
h5,
h6,
.mat-typography h1,
.mat-typography h2,
.mat-typography h3,
.mat-typography h4,
.mat-typography h5,
.mat-typography h6,
p,
.mat-typography p,
span,
strong,
body {
  margin: 0;
  font-family: $roboto;
  &.is-light {
    color: $colTertiaryLightgrey2;
  }
}

p,
.mat-typography p {
  margin-bottom: 1em;
  font-size: rem(14px);
  line-height: 1.5;

  @include bp(desk) {
    font-size: 1rem;
  }
}

body {
  font-family: $fontBody;
  font-weight: $fontBodyWeight;
  font-size: 1rem;
  line-height: 1.5;
}

strong,
b {
  font-weight: $fontHeadingWeight;
}

a {
  text-decoration: none;
  transition: text-decoration 0.25s, color 0.25s;

  &:hover {
    cursor: pointer;
    color: var(--primary-color);
  }

  &.is-light,
  &.is-light:visited {
    color: $colTertiaryLightgrey2;
    transition: color 0.25s;

    &:hover {
      color: var(--primary-color);
    }
  }
}

.disclaimer {
  h2,
  h3,
  h4,
  h5 {
    color: $colTertiaryMediumgrey3;
    padding: 0.5rem 0;
    margin-bottom: 0.5rem;
  }

  h4 {
    border-bottom: 1px solid $colTertiaryLightgrey3;
  }

  a {
    color: var(--primary-color);
    transition: text-decoration 0.25s;
    &:hover {
      text-decoration: underline;
    }
  }
}
