nb-windows-container {
  flex-wrap: wrap;
  margin-bottom: 3rem;

  @include bp(tab-lg) {
    margin-bottom: 0;
    flex-wrap: nowrap;
  }

  nb-window {
    min-width: 24rem;
    width: 100%;

    @include bp(tab-lg) {
      max-width: 24rem;
      min-width: 24rem !important;
    }

    &:not(.full-screen) {
      margin: 0 !important;

      @include bp(tab-lg) {
        margin: 0 1rem 0 0 !important;
      }
    }

    &.full-screen {
      padding: 0 1.5rem;

      @include bp(tab-lg) {
        min-width: 70vh !important;
      }

      nb-card {
        max-height: 60vh;
      }
    }

    &.minimized nb-icon[icon="minus-outline"] {
      position: relative;
      &:before {
        content: "";
        background: url("../../assets/images/icons/icon-window-maximize.svg")
          no-repeat !important;
        background-size: 0.75rem !important;
        position: absolute;
        width: 0.75rem;
        height: 0.75rem;
        left: 0.25rem;
        top: 0.25rem;
      }
      svg {
        display: none;
      }
    }
  }
}

nb-card-header {
  padding: 0.75rem 1rem;

  @include bp(tab-lg) {
    padding: 1rem;
  }

  button {
    flex-basis: 2.5rem !important;
    padding: 0.25rem !important;
  }
}

.nb-theme-default nb-chat {
  nb-chat-message {
    margin-bottom: 1rem;

    nb-chat-message-text {
      .text {
        padding: 0.5rem;
        margin-bottom: 0.5rem;
      }
    }

    &.not-reply nb-chat-message-text .text {
      background: var(--primary-color);
    }
  }

  nb-chat-form {
    padding: 0.75rem;

    .message-row {
      input {
        font-weight: 400 !important;
        border-color: $colTertiaryLightgrey2 !important;
      }

      .send-button {
        background-color: var(--primary-color) !important;
        border-color: var(--primary-color) !important;
      }
    }
  }
}

nb-window.chatbox > nb-card > nb-card-body {
  padding: 0;
}
