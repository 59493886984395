// We have to override the 'nebular' theme styles
.navigation-content-wrapper {
  color: #fff;
  h1, h2, h3, h4, h5, h6 {
    color: #fff;
  }
  p {
    color: #fff;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
