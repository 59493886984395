@mixin bp($point) {
  $mob: "(min-width: 460px)";
  $tab: "(min-width: 645px)";
  $tab-lg: "(min-width: 960px)";
  $desk: "(min-width: 1100px)";
  $d-wide: "(min-width: 1440px)";
  $wide: "(min-width: 1925px)";
  $x-wide: "(min-width: 2460px)";
  $mob-land: "(orientation: landscape) and (min-width: 320px) and (max-width: 960px)";

  @if $point == mob {
    @media #{$mob} {
      @content;
    }
  }

  @if $point == tab {
    @media #{$tab} {
      @content;
    }
  }

  @if $point == tab-lg {
    @media #{$tab-lg} {
      @content;
    }
  }

  @if $point == desk {
    @media #{$desk} {
      @content;
    }
  }

  @if $point == dwide {
    @media #{$d-wide} {
      @content;
    }
  } @else if $point == wide {
    @media #{$wide} {
      @content;
    }
  } @else if $point == xwide {
    @media #{$x-wide} {
      @content;
    }
  }

  @if $point == mobLand {
    @media #{$mob-land} {
      @content;
    }
  }
}

@function rem($size) {
  $remSize: calc($size / 16px);
  @return #{$remSize}rem;
}

@mixin container() {
  max-width: $container-width;
  margin: 0 auto;
  width: 100%;
  padding: rem(40px) rem(24px);
}
