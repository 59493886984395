mat-slider {
  &.sliderHorizontal {
    display: block;
    @include bp(tab-lg) {
      display: none;
    }
  }

  &.sliderVertical {
    display: none;
    @include bp(tab-lg) {
      display: block;
    }
  }

  &.mat-slider-horizontal {
    .mat-slider-track-wrapper {
      background: $colTertiaryMediumgrey2;
      height: 0.25rem;
      border-radius: 0.25rem;
      top: -1px;

      .mat-slider-track-background,
      .mat-slider-track-fill {
        height: 0.25rem;
      }
    }
  }

  &.mat-slider-vertical {
    min-height: 20rem !important;
    margin-bottom: 0.5rem;

    .mat-slider-track-wrapper {
      background: linear-gradient(
        to right,
        rgb(102, 106, 104) 0%,
        rgb(102, 106, 104) 0%,
        rgb(0, 0, 0) 0%,
        black 100%
      );
      width: 0.25rem;
      border-radius: 0.25rem;
      left: -1px;

      .mat-slider-track-fill {
        width: 0.25rem;
      }
    }
  }
}

.directionVerticalSmall {
  .mat-icon-button .mat-icon {
    font-size: 20px;

    @include bp(xwide) {
      font-size: 24px;
    }
  }

  mat-slider.mat-slider-vertical {
    min-height: 4.5rem !important;
    margin-bottom: 0rem;

    @include bp(xwide) {
      min-height: 6rem !important;
    }
  }
}
