.mat-snack-bar-container{
  &.success{
    background-color: var(--primary-color);
    color: $white;
  }
  &.error{
    background-color: $colPrimaryOrange;
    color: $white;
  }
}
