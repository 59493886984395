//Generic Colors
$white: #ffffff;
$black: black;

//Gatho Colours
$colPrimaryPink: #ff249d;
$colPrimaryOrange: #ff514c;

$colSuccessGreen: #3EB54A;

$colTertiaryLightgrey1: #fbfcfc;
$colTertiaryLightgrey2: #f3f5f4;
$colTertiaryLightgrey3: #ced2d0;
$colTertiaryMediumgrey1: #929895;
$colTertiaryMediumgrey2: #8a8f8e;
$colTertiaryMediumgrey3: #666a68;
$colTertiaryDarkgrey1: #3f4241;
$colTertiaryDarkgrey2: #222524;
$colTertiaryDarkgrey3: #1e2120;

$colGradient180: linear-gradient(180deg, $colPrimaryPink 0, $colPrimaryOrange);
$colGradient90: linear-gradient(90deg, $colPrimaryOrange 0, $colPrimaryPink);
$colGradient0: linear-gradient(0deg, $colPrimaryPink 0, $colPrimaryOrange);

$colGradientHorDarkToLight: linear-gradient(
  90deg,
  $colTertiaryDarkgrey3 0,
  $colTertiaryDarkgrey1
);
$colGradientHorLightToDark: linear-gradient(
  90deg,
  $colTertiaryDarkgrey1 0,
  $colTertiaryDarkgrey3
);
$colGradientVerLightToDark: linear-gradient(
  180deg,
  $colTertiaryDarkgrey1 0,
  $colTertiaryDarkgrey3
);
$colGradientVerDarkToLight: linear-gradient(
  180deg,
  $colTertiaryDarkgrey3 0,
  $colTertiaryDarkgrey1
);

$colBody: #353938;

//Drop shadow
$boxShadow: 0 0 45px rgb(0 0 0 / 10%);
$boxShadowBottomSheet: 0 8px 10px -5px rgb(0 0 0 / 20%),
  0 16px 24px 2px rgb(0 0 0 / 14%), 0 6px 30px 5px rgb(0 0 0 / 12%);

//Borders
$border-radius: 30px;

//Text
$roboto: "Roboto", sans-serif;
$fontHeading: $roboto;
$fontHeadingWeight: 500;
$fontBody: $roboto;
$fontBodyWeight: 400;
