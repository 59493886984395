* {
  &::-webkit-scrollbar {
    width: 0.25rem;
    height: 0.25rem;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0.25rem;
    background: $colTertiaryMediumgrey2;
  }
  &.hasScrollbarLight {
    &::-webkit-scrollbar {
      width: 0.5rem;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 0.25rem;
      background-color: transparent;
      box-shadow: inset 0 0 1rem $colTertiaryLightgrey2;
      border-top: solid 2rem transparent;
      border-right: solid 0.25rem transparent;
    }
  }
}
