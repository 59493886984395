// Daisy UI Avatar component
.avatar > div {
  // fallback for legacy browser support
  @supports not (aspect-ratio: 1 / 1) {
    &:before {
      float: left;
      padding-top: 100%;
      content: "";
    }

    &:after {
      display: block;
      content: "";
      clear: both;
    }
  }
}
