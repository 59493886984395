@forward '@nebular/theme/styles/theming';
@use '@nebular/theme/styles/theming' as *;
@use '@nebular/theme/styles/themes/default';

@import "scss/config/mixins";
@import "scss/config/variables";


:host {
  --primary-color: ${$colPrimaryPink};
}

$nb-enable-css-custom-properties: false;

$nb-themes: nb-register-theme((

  // add your variables here like:
  layout-padding: 0,
  layout-medium-padding: 0,
  layout-small-padding: 0,

  font-family-primary: $roboto,

  color-primary-100: var(--primary-color),
  color-primary-200: var(--primary-color),
  color-primary-300: var(--primary-color),
  color-primary-400: #929895, // color-primary-hover, color-primary-hover-border
  color-primary-500: var(--primary-color), // color-primary-default, color-primary-default-border
  color-primary-600: var(--primary-color), // color-primary-focus, color-primary-active, color-primary-active-border
  color-primary-700: var(--primary-color), // color-primary-focus-border
  color-primary-800: var(--primary-color),
  color-primary-900: var(--primary-color),

), default, default);

.nb-theme-default a {
  color: var(--primary-color);
  text-decoration: none !important;
}
