.mat-tab-labels {
  justify-content: center;

  .mat-tab-label {
    min-width: auto !important;
    height: 2.5rem !important;
    padding: 1rem 2rem 0.5rem;
    opacity: 1 !important;
    color: $colTertiaryMediumgrey2;

    &:focus:not(.mat-tab-disabled) {
      color: $colTertiaryDarkgrey3;
    }

    // .is-primary .badge {
    //   background: #af0060;
    // }

    .mat-tab-label-content {
      font-size: 1rem;
      line-height: 1.2;
    }
  }
}

.mat-tab-label-active {
  // opacity: 1 !important;
  color: $colTertiaryDarkgrey3;

  // .is-primary .badge {
  //   background: $colPrimaryPink;
  // }
}
// .mat-tab-label:focus:not(.mat-tab-disabled)

.mat-ink-bar {
  background-color: var(--primary-color) !important;
}

.mat-tab-body-wrapper {
  padding: 2rem 0 0;
}
