.mat-bottom-sheet-container-xlarge,
.mat-bottom-sheet-container-large,
.mat-bottom-sheet-container-medium,
.mat-bottom-sheet-container {
  border-radius: $border-radius $border-radius 0 0 !important;
  padding: 0.5rem 0 1rem 0 !important;
  margin-bottom: 2rem;
  overflow: hidden !important;
  max-height: 60vh !important;

  @include bp(tab-lg) {
    margin-bottom: 0;
  }

  @include bp(mobLand) {
    overflow: auto !important;
    max-height: 100vh !important;
  }

  .horizontalBar {
    background: $colTertiaryLightgrey3;
    border-radius: 0.25rem;
    height: 0.25rem;
    width: 16rem;
    margin-left: 50%;
    transform: translateX(-50%);

    @include bp(tab-lg) {
      display: none;
    }
  }

  .btnClose {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    color: $colTertiaryMediumgrey1;
  }
}

.mat-bottom-sheet-container-xlarge,
.mat-bottom-sheet-container-large,
.mat-bottom-sheet-container-medium {
  min-width: 50rem !important;
  max-height: 80vh !important;
}

.cdk-overlay-pane {
  &.disclaimer {
    .mat-bottom-sheet-container-xlarge,
    .mat-bottom-sheet-container-large,
    .mat-bottom-sheet-container-medium,
    .mat-bottom-sheet-container {
      border-radius: 0.5rem 0.5rem 0 0 !important;
      max-height: 100vh !important;
      margin-top: 1rem;
      margin-bottom: 0;
      padding: 1.5rem !important;
      max-width: 50rem;
    }

    .disclaimer__title {
      padding: 1.5rem 0;
      text-align: center;
      color: $colTertiaryMediumgrey3;

      @include bp(tab) {
        padding: 2rem 0;
      }

      @include bp(tab-lg) {
        padding: 3rem 0;
      }
    }

    .disclaimer__content {
      overflow-y: scroll;
      height: 85%;
      padding: 0.5rem;
      color: $colTertiaryMediumgrey3;

      @include bp(tab) {
        padding: 0 2rem;
      }
    }
  }
}
