:host {
  //Primary buttons
  --button-primary-text-color: ${$white};
  --button-primary-start-color: ${$colPrimaryOrange};
  --button-primary-end-color: ${$colPrimaryPink};

  --button-primary-text-color-hover: ${$colTertiaryDarkgrey1};
  --button-primary-start-color-hover: ${$colTertiaryLightgrey2};
  --button-primary-end-color-hover: ${$colTertiaryLightgrey2};

  --button-primary-text-color-active: ${$colTertiaryDarkgrey1};
  --button-primary-start-color-active: ${$colTertiaryLightgrey2};
  --button-primary-end-color-active: ${$colTertiaryLightgrey2};

  --button-primary-text-color-focus: ${$colTertiaryDarkgrey1};
  --button-primary-start-color-focus: ${$colTertiaryLightgrey2};
  --button-primary-end-color-focus: ${$colTertiaryLightgrey2};

  //Secondary buttons
  --button-secondary-text-color: ${$white};
  --button-secondary-bg-color: ${$colTertiaryMediumgrey3};

  --button-secondary-text-color-hover: ${$colTertiaryDarkgrey1};
  --button-secondary-bg-color-hover: ${$colGradient90};

  --button-secondary-text-color-active: ${$colTertiaryDarkgrey1};
  --button-secondary-bg-color-active: ${$colGradient90};

  --button-secondary-text-color-focus: ${$colTertiaryDarkgrey1};
  --button-secondary-bg-color-focus: ${$colGradient90};

  //Icon buttons
  --button-icon-bg-start-color: ${$colPrimaryPink};
  --button-icon-bg-end-color: ${$colPrimaryOrange};
  --button-icon-color: ${$white};
}

.mat-flat-button,
.mat-stroked-button {
  width: 100%;
  font-size: rem(12px);
  line-height: 1.2 !important;
  font-weight: 600;
  padding: rem(10px) rem(24px) !important;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-radius: $border-radius !important;
  overflow: hidden !important;

  @include bp(desk) {
    font-size: rem(18px);
    padding: rem(12px) rem(32px) !important;
  }

  .mat-button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
  }
}

// Flat button - primary
.mat-flat-button.is-primary {
  background: linear-gradient(90deg, var(--button-primary-start-color), var(--button-primary-end-color));
  color: var(--button-primary-text-color);
  transition: color 0.25s, background 0.25s;

  &:hover {
    color: var(--button-primary-text-color-hover);
    background: linear-gradient(90deg, var(--button-primary-start-color-hover), var(--button-primary-end-color-hover));
  }

  &:focus {
    color: var(--button-primary-text-color-focus);
    background: linear-gradient(90deg, var(--button-primary-start-color-focus), var(--button-primary-end-color-focus));
  }

  &:active {
    color: var(--button-primary-text-color-active);
    background: linear-gradient(90deg, var(--button-primary-start-color-active), var(--button-primary-end-color-active));
  }

  &.mat-button-disabled {
    // default mat disabled button styles
    background: rgba(0, 0, 0, 0.12);

    &:hover {
      // default mat disabled button styles
      color: rgba(0, 0, 0, 0.26);
      background: rgba(0, 0, 0, 0.12);
    }
  }
}

// Flat button - secondary
.mat-flat-button.is-secondary {
  font-size: rem(12px);
  padding: rem(10px) rem(24px) !important;
  transition: background-color 0.25s;
  color: var(--button-secondary-text-color);
  background: var(--button-secondary-bg-color);

  &:hover {
    background: var(--button-secondary-bg-color-hover);
    color: var(--button-secondary-text-color-hover);
  }

  &:focus {
    background: var(--button-secondary-bg-color-focus);
    color: var(--button-secondary-text-color-focus);
  }

  &:active {
    background: var(--button-secondary-bg-color-active);
    color: var(--button-secondary-text-color-active);
  }

  &.mat-button-disabled {
    &:hover {
      // default mat disabled button styles
      background: rgba(0, 0, 0, 0.12);
    }
  }
}

// Stroked button
.mat-stroked-button {
  color: $white;
  border-color: $white !important;
  font-weight: 400;
  transition: color 0.25s, background-color 0.25s;
  &:hover {
    color: $colTertiaryMediumgrey1;
    background: $white;
  }

  &.mat-button-disabled {
    border-color: $colTertiaryLightgrey3 !important;
    color: $colTertiaryLightgrey3 !important;
    &:hover {
      background: transparent;
    }
  }

  &.is-dark {
    color: $colTertiaryMediumgrey3;
    border-color: $colTertiaryMediumgrey3 !important;
  }

  &.is-small {
    font-size: 0.75rem;
    padding: 0.5rem 1.25rem !important;
    text-transform: capitalize;
    letter-spacing: 0.5px;
  }
}

button {
  &.is-light {
    color: $white;
  }
}

// Button toggle group
.mat-button-toggle-appearance-standard {
  .mat-button-toggle-label-content {
    padding: 0 !important;
  }

  .mat-button-toggle-focus-overlay {
    background: transparent;
  }
}

.mat-button-toggle-button {
  @include bp(tab-lg) {
    width: 4rem !important;
  }
}

// Icon button
.mat-icon-button {
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 2rem !important;
  height: 2rem !important;
  color: $white;
  overflow: hidden !important;

  @include bp(xwide) {
    width: 2.5rem !important;
    height: 2.5rem !important;
  }

  .mat-icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

// Icon button - Primary
.mat-icon-button.is-primary {
  background: linear-gradient(var(--button-icon-bg-start-color), var(--button-icon-bg-end-color));
  color: var(--button-icon-color);
  transition: background 0.25s;

  // Icon button - Large primary
  &.is-large {
    @include bp(tab-lg) {
      width: 4rem !important;
      height: 4rem !important;
    }
  }

  &.has-bgHoverLight {
    @include bp(tab-lg) {
      &:hover {
        background: $white;
      }
    }
  }
}

// Icon button - Secondary
.mat-icon-button.is-secondary {
  background: rgba(0, 0, 0, 0.5);

  .mat-icon {
    font-size: 1.25rem;
  }

  @include bp(tab-lg) {
    width: 2rem !important;
    height: 2rem !important;
    background: none;

    .mat-icon {
      font-size: 1.5rem;
    }
  }
}

// Icon button - w/ light grey background
.mat-icon-button.has-bgLight {
  background: $colTertiaryLightgrey2;

  &.is-small {
    width: 2rem !important;
    height: 2rem !important;
    .mat-icon {
      height: 1rem;
      width: 1rem;
      font-size: 1rem;
    }
  }
}

// Icon button - w/ large icon
.mat-icon-button.has-iconLarge {
  .mat-icon {
    font-size: 2rem;
  }
}

// Icon button - w/ larger icon
.mat-icon-button.has-iconLarger {
  .mat-icon {
    font-size: 2.5rem;
  }
}

// Icon button - w/ small icon
.mat-icon-button.has-iconSmall {
  .mat-icon {
    height: 1.25rem;
    width: 1.25rem;
    font-size: 1.25rem;
  }
}

// Icon button - Left side Half pill shaped
.mat-icon-button.halfPillShapedLeft {
  background: $colGradient180;
  padding: 0.75rem 1rem 0.75rem 0.5rem;
  width: auto !important;
  height: auto !important;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

// Icon button - Right side Half pill shaped
.mat-icon-button.halfPillShapedRight {
  background: $colGradient180;
  padding: 0.75rem 0.5rem 0.75rem 1rem;
  width: auto !important;
  height: auto !important;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
