body {
  overflow-x: hidden;
}

ul {
  list-style: revert;
  margin: 1rem 0;
  padding-left: 1rem;
}

ul li,
ol li {
  color: $colTertiaryMediumgrey3;
  font-size: 1rem;

  &.heading {
    font-size: 1.5rem;
    line-height: 1.2;
    padding: 0.5rem 0;
  }
}

ol.counter {
  counter-reset: item;
  padding-left: 0;

  > li {
    display: block;
    position: relative;
    padding-left: 2rem;
    padding-bottom: 1rem;

    &:first-of-type {
      padding-top: 1rem;
    }
  }

  > li:before {
    content: counters(item, ".") " ";
    counter-increment: item;
    position: absolute;
    left: 0;
  }
}

ol.alphabet-counter {
  list-style-type: lower-alpha;
  padding-left: 1rem;
  padding-top: 1rem;
  li {
    padding-bottom: 1rem;
    padding-left: 0.5rem;

    &:last-of-type {
      padding-bottom: 0;
    }
  }
  li::marker {
    padding-right: 2rem;
  }
}

table.gathoCustom {
  padding-bottom: 1rem;
  display: flex;
  justify-content: center;
  td {
    padding-right: 2.5rem;
    vertical-align: baseline;
    color: $colTertiaryMediumgrey3;
  }
}

:focus-visible {
  outline: none;
}

// Material Angular progress spinner styles
.mat-progress-spinner,
.mat-spinner {
  margin: 1rem auto;
  svg circle {
    stroke: var(--primary-color);
  }
}

// Overrides the dialog and bottom sheet overlay backdrop opacity of 1, and stacks mobile bottom nav menu over the bottom sheet
.cdk-overlay-container {
  isolation: isolate;
  z-index: 9 !important;
  .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    opacity: 0.7;
  }
}
