// Material Angular divider custom styles
mat-divider {
  &.is-light {
    @include bp(tab-lg) {
      border-top-color: $colTertiaryLightgrey1;
    }
  }

  &.is-medium {
    @include bp(tab-lg) {
      border-top-color: rgba(0, 0, 0, 0.08);
    }
  }
}
