.cdk-overlay-pane {
  &.gathoDialog {
    mat-dialog-container {
      overflow: hidden;
      border-radius: $border-radius;
      padding: 2rem;
      position: relative;
      margin: 0 1.5rem;

      @include bp(mobLand) {
        padding-bottom: 4rem;
      }

      .mat-dialog-title {
        text-align: center;
        color: $colTertiaryMediumgrey1;
      }

      mat-dialog-content {
        margin-top: 2rem;
        margin-bottom: 1rem;
        color: $colTertiaryMediumgrey3;
      }
    }
  }

  mat-dialog-actions {
    position: absolute;
    top: 0rem;
    right: 0.5rem;

    button {
      color: $colTertiaryMediumgrey2;
      transition: color 0.25s;
      &:hover {
        color: var(--primary-color);
      }
    }
  }

  &.gathoDialog__bgGradient {
    mat-dialog-container {
      background: $colGradient90;
      border-radius: 11.25rem;
      padding: 1.5rem;

      mat-dialog-content {
        color: $white;
        padding: 0 2rem;
        margin-top: 0;
        margin-bottom: 1.5rem;

        @include bp(tab-lg) {
          padding: 0 3.5rem;
        }
      }
    }

    mat-dialog-actions {
      position: absolute;
      bottom: 1rem;
      top: auto;
      left: 50%;
      right: auto;
      transform: translateX(-50%);

      button {
        color: $white;
        opacity: 1;
        transition: opacity 0.25s;

        &:hover {
          opacity: 0.85;
        }
      }
    }
  }
}
